/* CSS Document */
/*__________________________________________________________________TAGS_____________________________________*/
#masterContainer{
    width:900px;
    margin:0 auto;
    font-family: Arial, Helvetica, sans-serif;
}

#invoiceText{
    float:right;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #0B004A;
}

#invoiceInfo{
    clear:right;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #000000;
    float:right;
    margin-bottom:1em;
}

#headerC1{
    float:left;
    clear:right;
}

#disclaimer{
    font-size:10px;
    margin-bottom:10px;
    font-family: Verdana, Geneva, sans-serif;
    font-style: italic;
    font-weight: normal;
    font-variant: normal;
}


#headerC1 img{
    height:100px;
}


#headerC2{
    float:right;
    height:160px;
    width:600px;

}

#clientCaption{
    text-align:center;
    color: #0B004A;
    background-color: #FFFFFF;
    margin: 4px;
}

#clientC1{
    width:45%;
    float:left;
    padding-left:10px;
    font-size:13px;
}

#clientC2{
    width:45%;
    float:right;
    padding-right:10px;
    font-size:13px;
}

#projectInfo{
    clear:both;
    margin-bottom:50px;
}

.titles{
    font-size: 20px;
    color: #0B004A;
    margin-bottom:5px;
}

#projectName{
    font-weight: bold;
}

.row{
    width:100%;
    background-color: #CCCCCC;
    font-size:14px;
    padding:2px;
    height:18px;
    margin-bottom:5px;
}

.rowSummary{
    width:100%;
    font-size:16px;
    padding:2px;
    height:18px;
    margin-bottom:5px;
}

.rowSummarySum{
    width:100%;
    font-size:16px;
    padding:2px;
    height:18px;
    margin-bottom:5px;
}

.rowRecap{
    width:100%;
    font-size:16px;
    padding:2px;
    height:18px;
    margin-bottom:5px;
    background-color: #CCCCCC;
}

.cost{
    float:right;


}

.total{
    float:right;
    color: #6E0000;
    font-weight: bold;
    font-style: italic;
}

.item{
    float:left;
}

p {
    margin-bottom:20px;
}

#summaryInfo{
    float:left;
    width:50%;
}

#threedLogo img{
    height:500px;
    float:right;
    margin-top:15px;
    margin-right:25px;
}

#paymentInfoTitle{
    color: #666666;
    clear:left;
    padding-top:25px;
    padding-bottom:10px;
    font-size: 14px;

}

#paymentInfo{
    color: #666666;
    clear:left;
    padding-bottom:10px;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
}

#paymentStatus{
    font-size: 24px;
    font-weight: bold;
    color: #CC0000;
    margin-top:20px;
}

a, a:visited{
    color: #000DF1;
    text-decoration: none;
}

a:hover{
    text-decoration: underline;
}

.urlCheck{
    font-size: 12px;
    padding-bottom:10px;
}

a:focus {outline: none;}

img { border:none;}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}