body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* CSS Document */
/*__________________________________________________________________TAGS_____________________________________*/
#masterContainer{
    width:900px;
    margin:0 auto;
    font-family: Arial, Helvetica, sans-serif;
}

#invoiceText{
    float:right;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #0B004A;
}

#invoiceInfo{
    clear:right;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #000000;
    float:right;
    margin-bottom:1em;
}

#headerC1{
    float:left;
    clear:right;
}

#disclaimer{
    font-size:10px;
    margin-bottom:10px;
    font-family: Verdana, Geneva, sans-serif;
    font-style: italic;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
}


#headerC1 img{
    height:100px;
}


#headerC2{
    float:right;
    height:160px;
    width:600px;

}

#clientCaption{
    text-align:center;
    color: #0B004A;
    background-color: #FFFFFF;
    margin: 4px;
}

#clientC1{
    width:45%;
    float:left;
    padding-left:10px;
    font-size:13px;
}

#clientC2{
    width:45%;
    float:right;
    padding-right:10px;
    font-size:13px;
}

#projectInfo{
    clear:both;
    margin-bottom:50px;
}

.titles{
    font-size: 20px;
    color: #0B004A;
    margin-bottom:5px;
}

#projectName{
    font-weight: bold;
}

.row{
    width:100%;
    background-color: #CCCCCC;
    font-size:14px;
    padding:2px;
    height:18px;
    margin-bottom:5px;
}

.rowSummary{
    width:100%;
    font-size:16px;
    padding:2px;
    height:18px;
    margin-bottom:5px;
}

.rowSummarySum{
    width:100%;
    font-size:16px;
    padding:2px;
    height:18px;
    margin-bottom:5px;
}

.rowRecap{
    width:100%;
    font-size:16px;
    padding:2px;
    height:18px;
    margin-bottom:5px;
    background-color: #CCCCCC;
}

.cost{
    float:right;


}

.total{
    float:right;
    color: #6E0000;
    font-weight: bold;
    font-style: italic;
}

.item{
    float:left;
}

p {
    margin-bottom:20px;
}

#summaryInfo{
    float:left;
    width:50%;
}

#threedLogo img{
    height:500px;
    float:right;
    margin-top:15px;
    margin-right:25px;
}

#paymentInfoTitle{
    color: #666666;
    clear:left;
    padding-top:25px;
    padding-bottom:10px;
    font-size: 14px;

}

#paymentInfo{
    color: #666666;
    clear:left;
    padding-bottom:10px;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
}

#paymentStatus{
    font-size: 24px;
    font-weight: bold;
    color: #CC0000;
    margin-top:20px;
}

a, a:visited{
    color: #000DF1;
    text-decoration: none;
}

a:hover{
    text-decoration: underline;
}

.urlCheck{
    font-size: 12px;
    padding-bottom:10px;
}

a:focus {outline: none;}

img { border:none;}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

